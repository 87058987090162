// The Grid
// =========================================================

.gr {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0; }

.gc {
  flex: 1;
  min-width: 1px; }

.gr--flexc > .gc {
  display: flex; }

.gr--top {
  align-items: flex-start; }

.gr--bottom {
  align-items: flex-end; }

.gr--center {
  align-items: center; }

.gr--jcenter {
  justify-content: center; }

.gc--top {
  align-self: flex-start; }

.gc--bottom {
  align-self: flex-end; }

.gc--center {
  align-self: center; }

.gc--auto {
  flex: none; }

.gr--fit > .gc {
  flex: 1; }

.gr--full > .gc {
  flex: 0 0 100%; }

.gr--1of2 > .gc {
  flex: 0 0 50%; }

.gr--1of3 > .gc {
  flex: 0 0 33.3333%; }

.gr--1of4 > .gc {
  flex: 0 0 25%; }

@media only screen and (min-width: 30em) {

  .s-gr--fit > .gc {
    flex: 1; }

  .s-gr--full > .gc {
    flex: 0 0 100%; }

  .s-gr--1of2 > .gc {
    flex: 0 0 50%; }

  .s-gr--1of3 > .gc {
    flex: 0 0 33.3333%; }

  .s-gr--1of4 > .gc {
    flex: 0 0 25%; } }

@media only screen and (min-width: 48em) {

  .m-gr--fit > .gc {
    flex: 1; }

  .m-gr--full > .gc {
    flex: 0 0 100%; }

  .m-gr--1of2 > .gc {
    flex: 0 0 50%; }

  .m-gr--1of3 > .gc {
    flex: 0 0 33.3333%; }

  .m-gr--1of4 > .gc {
    flex: 0 0 25%; } }

@media only screen and (min-width: 64em) {

  .l-gr--fit > .gc {
    flex: 1; }

  .l-gr--full > .gc {
    flex: 0 0 100%; }

  .l-gr--1of2 > .gc {
    flex: 0 0 50%; }

  .l-gr--1of3 > .gc {
    flex: 0 0 33.3333%; }

  .l-gr--1of4 > .gc {
    flex: 0 0 25%; } }

// !! Globally killed Gutter margin-bottom. Be careful on stacked grid containers !!

.gr--g {
  margin: -1em 0 0 -1em; }  // container class for grid cell gutters

.gr--g > .gc {
  padding: 1em 0 0 1em; } // standard grid cell gutters

.gr--g-l {
  margin: -1.5em 0 0 -1.5em; } // container class for large grid cell gutters

.gr--g-l > .gc {
  padding: 1.5em 0 0 1.5em; } // large grid cell gutters

.gr--g-xl {
  margin: -2em 0 0 -2em; } // container class for huge cell gutters

.gr--g-xl > .gc {
  padding: 2em 0 0 2em; } // huge grid cell gutters

@media only screen and (min-width: 30em) {

  .s-gr--g {
    margin: -1em 0 0 -1em; }

  .s-gr--g > .gc {
    padding: 1em 0 0 1em; }

  .s-gr--g-l {
    margin: -1.5em 0 0 -1.5em; }

  .s-gr--g-l > .gc {
    padding: 1.5em 0 0 1.5em; }

  .s-gr--g-xl {
    margin: -2em 0 0 -2em; }

  .s-gr--g-xl > .gc {
    padding: 2em 0 0 2em; } }

@media only screen and (min-width: 48em) {

  .m-gr--g {
    margin: -1em 0 0 -1em; }

  .m-gr--g > .gc {
    padding: 1em 0 0 1em; }

  .m-gr--g-l {
    margin: -1.5em 0 0 -1.5em; }

  .m-gr--g-l > .gc {
    padding: 1.5em 0 0 1.5em; }

  .m-gr--g-xl {
    margin: -2em 0 0 -2em; }

  .m-gr--g-xl > .gc {
    padding: 2em 0 0 2em; } }

@media only screen and (min-width: 64em) {

  .l-gr--g {
    margin: -1em 0 0 -1em; }

  .l-gr--g > .gc {
    padding: 1em 0 0 1em; }

  .l-gr--g-l {
    margin: -1.5em 0 0 -1.5em; }

  .l-gr--g-l > .gc {
    padding: 1.5em 0 0 1.5em; }

  .l-gr--g-xl {
    margin: -2em 0 0 -2em; }

  .l-gr--g-xl > .gc {
    padding: 2em 0 0 2em; } }

// Grid Utilities   ============================================
// https://github.com/philipwalton/solved-by-flexbox/blob/master/assets/css/utils/media.css

.u-full {
  width: 100% !important;
  flex: none !important; }

.u-1of2 {
  width: 50% !important;
  flex: none !important; }

.u-1of3 {
  width: 33.3333% !important;
  flex: none !important; }

.u-2of3 {
  width: 66.6667% !important;
  flex: none !important; }

.u-1of4 {
  width: 25% !important;
  flex: none !important; }

.u-3of4 {
  width: 75% !important;
  flex: none !important; }

@media only screen and (min-width: 30em) {

  .u-s-full {
    width: 100% !important;
    flex: none !important; }

  .u-s-1of2 {
    width: 50% !important;
    flex: none !important; }

  .u-s-1of3 {
    width: 33.3333% !important;
    flex: none !important; }

  .u-s-2of3 {
    width: 66.6667% !important;
    flex: none !important; }

  .u-s-1of4 {
    width: 25% !important;
    flex: none !important; }

  .u-s-3of4 {
    width: 75% !important;
    flex: none !important; } }

@media only screen and (min-width: 48em) {

  .u-m-full {
    width: 100% !important;
    flex: none !important; }

  .u-m-1of2 {
    width: 50% !important;
    flex: none !important; }

  .u-m-1of3 {
    width: 33.3333% !important;
    flex: none !important; }

  .u-m-2of3 {
    width: 66.6667% !important;
    flex: none !important; }

  .u-m-1of4 {
    width: 25% !important;
    flex: none !important; }

  .u-m-3of4 {
    width: 75% !important;
    flex: none !important; } }

@media only screen and (min-width: 64em) {

  .u-l-full {
    width: 100% !important;
    flex: none !important; }

  .u-l-1of2 {
    width: 50% !important;
    flex: none !important; }

  .u-l-1of3 {
    width: 33.3333% !important;
    flex: none !important; }

  .u-l-2of3 {
    width: 66.6667% !important;
    flex: none !important; }

  .u-l-1of4 {
    width: 25% !important;
    flex: none !important; }

  .u-l-3of4 {
    width: 75% !important;
    flex: none !important; } }
