// Header > Global
// =========================================================

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;

  // add margin due to fixed header
  + div {
    margin-top: 6.5em; // 104

    @media only screen and (min-width: 30em) {
      margin-top: 6.75em; } // 108

    @media only screen and (min-width: 48em) {
      margin-top: 6.6875em; } // 107

    @media only screen and (min-width: 64em) {
      margin-top: 7.6875em; } } // 123

  .btn-print {
    display: inline-block;
    padding: 0.42105em 1.10526em;
    font-family: "proxima-nova",sans-serif;
    font-size: 1.1875em;
    font-weight: 600;
    line-height: 1;
    color: #4a184f;
    text-decoration: none;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;

    &:before {
      content: none; } } }



// Header > Main
// =========================================================

.header__main {
  padding: {
    top: .75em; // 12
    bottom: .75em; } // 12
  box-shadow: 0 1px 3px rgba(0,0,0,.1);

  @media only screen and (min-width: 30em) {
    padding: {
      top: 1em; // 16
      bottom: .75em; } } // 12

  @media only screen and (min-width: 64em) {
    padding: {
      top: 1.5em; // 24
      bottom: 1.25em; } } // 20

  .inside {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center; } }

.logo {

  @media only screen and (min-width: 48em) {
    margin-top: -.5em; }

  img {
    max-width: 8.4375em; // 135

    @media only screen and (min-width: 48em) {
      max-width: 10em; } // 160

    @media only screen and (min-width: 64em) {
      max-width: 100%; } } }



// Header > Meta
// =========================================================

.header__meta {
  z-index: 9999;
  height: 2.0625em; // 33
  padding: {
    top: .375em; // 6
    bottom: .375em; } // 6
  color: #fff;

  @media only screen and (min-width: 48em) {
    padding: {
      top: .25em; // 6
      bottom: .3125em; } } // 6

  .inside {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      font: {
        size: .8125em;
        weight: 400; }
      color: #fff;

      &:hover {
        color: $green-light; }

      &:active {
        color: $green; } } } }
