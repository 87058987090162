// Links Basics
// =========================================================

a {
  font-weight: 700;
  color: $corporate;
  text-decoration: none;
  @include transition(all 0.15s ease-out);

  &:hover,
  &:focus {
    color: $green; }

  &:active {
    color: $corporate; } }

.content {

  a:not(.btn) {
    font-weight: 400;
    color: $green;

    &:hover,
    &:focus {
      color: $corporate; }

    &:active {
      color: $green; } } }



// Buttons
// =========================================================

.btn {
  display: inline-block;
  @include padding(.421052632em 1.105263158em); // 8 21
  font: {
    family: $proxima;
    size: 1.1875em; // 19
    weight: 600; }
  line-height: 1;
  color: $corporate;
  text-decoration: none;
  border: 1px solid $border;
  border-radius: 0 4px 4px 4px;
  box-shadow: 1px 1px 3px rgba(0,2,6,.1);
  background-color: #fff;

  &:before {
    content: ">";
    margin-right: .736842105em; // 14
    font-weight: 700;
    color: inherit; }

  &:hover,
  &:focus {
    color: #fff;
    background-color: $corporate;
    box-shadow: 0 0 0 rgba(0,2,6,.1); }

  &:active {
    box-shadow: inset 1px 1px 3px rgba(0,2,6,.2); } }

// Green Button
// ------------------------------------------

.btn--green,
.btn--teaser {
  color: #fff;
  background-color: $green;
  border-color: $border-green;

  &:hover,
  &:focus {
    background-color: $green-light; }

  &:active {
    background-color: $green; } }



// Blue Button
// ------------------------------------------

.btn--blue {
  color: #fff;
  background-color: $corporate;
  border-color: $corporate;

  &:hover,
  &:focus {
    background-color: $blue-medium; }

  &:active {
    background-color: $corporate; } }



// Big Button
// ------------------------------------------

.btn--big {
  @include padding(.842105263em 2.105263158em); } // 16 40



// Small Button
// ------------------------------------------

.btn--small {
  @include padding(.375em .875em); // 6 14
  font-size: 1em; } // 16



// Info Button
// ------------------------------------------

.btn--info {
  padding-left: 1.333333333em; // 24
  font-weight: 400;
  background: {
    image: url('../Icons/icon-info.svg');
    size: 18px 18px;
    position: left center;
    repeat: no-repeat; } }

