// Search Basics
// =========================================================

.tx-indexedsearch {

  h1 {
    text-align: center;

    + p {
      margin-bottom: 2em; } } // 48

  form {
    @include margin(1em auto 3em);

    @media only screen and (min-width: 48em) {
      max-width: 60%; }

    .tx-indexedsearch-searchbox-sword {
      width: 100%; }

    input[type="search"] {
      border: 1px solid $corporate-border;
      border-right: 0;
      @include transition(all 0.15s ease-out);

      &:focus {
        border: 1px solid $border;
        border-right: 0; } }

    button {
      background-color: $corporate;
      @include transition(all 0.15s ease-out);

      &:hover {
        background-color: $green; }

      &:active {
        background-color: $green-light; } } } }



// Search > Details
// ------------------------------------------

.tx-indexedsearch {

  &-whatis,
  &-browsebox,
  &-noresults {
    text-align: center;

    span {
      font-weight: 700; }

    + .nav__pagination {
      margin-top: 0;
      margin-bottom: 3em;
      text-align: center; } }

  &-whatis {

    + .tx-indexedsearch-browsebox {
      margin-top: 0; } }

  &-browsebox {
    margin-bottom: 3em; } // 48

  &-noresults {
    margin-top: .75em;
    color: $red; } }



// Search > Results
// ------------------------------------------

.tx-indexedsearch-res {

  ul {
    display: block;
    @include margin(0 null 1.5em);
    @include padding(null null null 0);
    list-style-type: none; }

  li {

    a {
      display: inline-block;
      margin-bottom: .25em; // 4px
      text-decoration: underline;

      @media only screen and (min-width: 30em) {
        font-size: 1.125em; // 18px
        margin-bottom: .222222222em; } } // 4px

    span {
      margin-top: .25em; // 4px
      font-weight: 400;
      color: $corporate-border-dark; } } }
