.news__single {

  h1 {
    margin-bottom: 1.2em; } // 48

  h4 {
    margin-top: 1.2em; // 24

    + ul {
      margin-top: 0; } }

  ul {

    > li {

      > ul {
        padding-left: 1em; } } }

  .content {

    img {
      border-radius: .25em .5em .5em .5em;
      border: 1px solid $corporate-border; } } }

.news__single--success-story {

  img {
    border: none;
    border-color: transparent; } }

.news-list-category {
  display: block;
  font-size: 1em; // 16
  color: #ffffff; //$blue-light-text

  &.background--light {
    color: $corporate; } }

.news-list-category-detail {
  display: block;
  font-size: 1em; // 16
  color: $green; //$blue-light-text

  &.background--light {
    color: $corporate; } }


.news__backlink {
  margin-top: 3em; // 48px
  text-align: center; }
