// Content Module: Centersearhc CTA
// =========================================================

.content__mod--cta-center {

  .form__input-group {
    width: 100%;
    margin-top: 1.5em;

    input[type="search"] {
      width: 50%; }

    .btn {
      border-radius: 0 4px 4px 0;

      @media only screen and (max-width: 30em) {
        font-size: 0; }

      &:before {

        @media only screen and (max-width: 30em) {
          margin-left: 0.736842105em;
          font-size: 19px; } } } } }
