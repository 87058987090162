// Navigation > Sidebar
// =========================================================

.nav__sidebar {

  li {
    @include padding(.285714286em .571428571em); // 4 8
    font-size: .875em; // 14
    border-bottom: 1px solid $corporate-border;

    &:first-child {
      border-top: 1px solid $corporate-border; }

    &:last-child {
      border-bottom: none; }

    &.active {

      a {
        color: $green; } } }

  a {
    display: block;
    font-weight: 600; } }
