// html, body
//   background-color: #fffcda

// section
//   background-color: #fff

// .header__meta
//   display: none !important

.nav__header--main {
  a[href*="suche"] {
    display: none; } }

.cta {
  button {
    font-size: 0;

    &:before {
      font-size: 19px;
      margin-left: 0.736842105em; } }

  @media only screen and (max-width: 30em) {
    input {
      width: 75% !important; } } }

// .nav__header--mobile
//  display: none !important

.stage__prinzipien-box {

  .box__teaser {

    button {
      float: right; } } }

body {
  .nav__header--mobile {
    display: none; } }

.fix-anchor {

  &:before {
    display: block;
    content: "";
    height: 125px;
    margin-top: -125px;
    visibility: hidden; } }

.no-flexbox {

  * {
    color: red !important; } }

.Extbase-Utility-Debugger-VarDump {
  z-index: 9999; }
