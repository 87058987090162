// // Content Module: Text & Image / Headline & Image
// =========================================================

.content__mod--text-image {

  @media only screen and (min-width: 30em) {

    .float__right,
    .float__left {
      max-width: 50%; } }

  img {
    border-radius: .25em .5em .5em .5em; } // 4 8

  ul {
    margin: 1.5em 0; } }
