// Box Basic
// =========================================================

.box {
  @include padding(1em); // 16px
  border-radius: .25em .5em .5em .5em; // 4px 8px

  @media only screen and (min-width: 46em) {
    @include padding(1.625em); } // 26px

  &.box__no-padding {
    @include padding(0); } }

.box__widget {
  @include padding(1em); // 16px
  border-radius: .25em .5em .5em .5em; // 4px 8px

  @media only screen and (min-width: 46em) {
    @include padding(1em); } // 26px

  &.box__no-padding {
    @include padding(0); } }


.box__ad {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  min-height: 12.5em; // 200
  margin-bottom: 3em; //48
  background: {
    position: center center;
    repeat: no-repeat;
    size: cover;
    attachment: scroll; }

  @media only screen and (min-width: 48em) {
    width: 48%;
    margin-bottom: 0;
    margin-right: 4%; } }

.box__teasers {
  display: flex;
  flex-direction: column;
  width: 100%;
  // min-height: 12.5em // 200
  margin-bottom: 3em; //48
  background: {
    position: center center;
    repeat: no-repeat;
    size: cover;
    attachment: scroll; }

  @media only screen and (min-width: 48em) {
    width: 48%;
    margin-bottom: 0;
    margin-right: 4%;

    &:nth-of-type(even) {
      margin-right: 0; }

    // &:nth-of-type(3),
    // &:nth-of-type(4),
    // &:nth-of-type(5),
    // &:nth-of-type(6)
 } }    //   margin-top: 2em

.sidebar {

  .box__ad {
    width: 100%;
    margin-right: 0;

    h3 {
      margin: 0; }
    p {
      margin-bottom: 0.5em; }
    a {
      font-size: 1em; } } }

.box__centers,
.box__teasers {
  width: 100%;
  // background:
  //   position: center center
  //   repeat: no-repeat
  //   size: cover
  //   attachment: scroll

  @media only screen and (min-width: 48em) {
    width: 48%; }

  .org {

    > a {
      font-weight: 600;
      color: $corporate;

      &:hover {
        color: $green; }

      &:active {
        color: $corporate; } } }

  > .btn {
    margin-top: 1em; } }

.sidebar {

  .box__teasers {
    width: 100%; } }

.sidebar {

  .box__widget {
    justify-content: center; } }
