// Content Module: Text & Marginal
// =========================================================

.content__mod--text-marginal {

  figure {

    @media only screen and (min-width: 48em) {
      padding: .5em 0 .5em 1em;
      border-left: 2px solid $corporate-border; } } }
