// Content Module: Text, Image & CTA
// =========================================================

.content__mod--cta-image {
  @include padding(1em); // 16
  box-shadow: 1px 1px 3px rgba(0,2,6,0.1);
  border: 1px solid $corporate-border;
  border-radius: .25em .5em .5em .5em; // 4 8
  background-color: $blue-light;
  overflow: hidden;

  @media only screen and (min-width: 48em) {
    @include padding(1.625em); } // 26

  img {
    max-width: 9.375em; // 150
    border: 2px solid $corporate-border;
    border-radius: 50%;

    @media only screen and (min-width: 48em) {
      max-width: 18.75em; // 300
      margin-bottom: 1.5em;
      border-width: 1px;
      border-radius: .25em .5em .5em .5em; } // 4 8

    @media only screen and (min-width: 64em) {
      @include margin(-1.625em -1.625em -1.625em null);
      border: none;
      border-radius: 0; } }

  h3,
  p {
    text-align: center;

    @media only screen and (min-width: 48em) {
      text-align: left; } } }
