// Stage > Static
// =========================================================

.stage__static {

  &--content,
  &--center {
    position: relative;
    max-width: 75em; // 1200
    min-height: 1px;
    @include margin(0 auto);
    background: {
      repeat: no-repeat;
      size: cover;
      attachment: scroll; } } }


.stage__static--content {
  @include padding(2.25em 2.5%); // 36
  background: {
    color: $blue-medium;
    position: center center; }

  @media only screen and (min-width: 48em) {
    min-height: 31.25em; } // 500

  @media only screen and (min-width: 48em) {
    @include padding(3.75em 5% 2.25em); } // 60 36

  @media only screen and (min-width: 64em) {
    @include padding(4.5em 0 2.25em 8.75em); } // 72 0 36 140

  .checkmarks {
    @include margin(null null 1.5em null); // 24
    @include padding(null null null .375em); // 6

    li {
      background: url('../Icons/icon-checkmark.png') no-repeat left top;
      margin-bottom: .25em; // 4
      padding-left: 2em; //32

      &:last-child {
        margin-bottom: 0; } } } }



// Stage > Static > Center
// ------------------------------------------

.stage__static--center {
  background: {
    color: $blue-medium;
    position: left center; }

  h1 {
    font-size: 1.75em;

    @media only screen and (min-width: 30em) {
      font-size: 1.75em; } } }
