// Navigation > Standard Pagination (used for articles)
// =========================================================

.nav__pagination,
.tx-indexedsearch-browsebox {
  display: block;
  margin-top: 3.75em; // 60px
  text-align: center;

  ul,
  .browsebox {
    margin: 0;
    padding: 0; }

  li {
    display: inline-block;
    margin: 0;
    font-size: 1.1875em; // 19px
    border: 1px solid $border;
    border-radius: 0 4px 4px 4px;
    box-shadow: 1px 1px 3px rgba(0,2,6,0.1);
    background-color: #fff;

    @media only screen and (min-width: 30em) {
      @include margin(0 .133333333em); } // 2px

    &.current,
    &.tx-indexedsearch-browselist-currentPage {
      @include padding(.210526316em .789473684em); // 4px 19px
      font-weight: 600;
      color: #fff;
      background-color: $corporate; }

    &.nav__pagination--dots {
      @include padding(.210526316em .789473684em); // 4px 19px
      font-weight: 600; }

    &.previous,
    &.next,
    &.browsebox--next,
    &.browsebox--prev {

      a {
        font-weight: 700; } } } }

.content {

  .nav__pagination,
  .tx-indexedsearch-browsebox {

    a {
      display: block;
      @include padding(.210526316em .789473684em); // 4px 19px
      font-weight: 600;
      color: $corporate;

      &:hover,
      &:focus {
        color: #fff;
        background-color: $corporate;
        box-shadow: 0 0 0 rgba(0,2,6,0.1); }

      &:active {
        box-shadow: inset 1px 1px 3px rgba(0,2,6,0.2); } }

    li {

      &.current,
      &.tx-indexedsearch-browselist-currentPage {

        a {
          padding: 0;
          color: #fff; } } } } }
