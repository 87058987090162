//  Forms
// =========================================================

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

textarea {
  resize: vertical; }

input,
textarea {

  &:focus {
    outline: 0;
    outline: thin dotted \9;
    box-shadow: 0 0 0 0 rgba(0,0,0,0); } }

::-webkit-input-placeholder {
  color: #999; }

 :-moz-placeholder {
  color: #999; }

::-moz-placeholder {
  color: #999; }

 :-ms-input-placeholder {
  color: #999; }

.form__input-group {
  display: flex;

  & input[type="email"],
  & input[type="search"],
  & input[type="text"], {
    display: inline-block;
    min-width: 11.25em; // 180
    @include padding(.5em .25em .5em 1em); // 8 4 8 16
    font: {
      family: $proxima-cond;
      size: 1em;
      weight: 400; }
    line-height: 1.4;
    color: $corporate;
    font-style: normal;
    vertical-align: middle;
    *overflow: visible;
    -webkit-appearance: none;
    box-shadow: none;
    -webkit-backface-visibility: hidden;
    border: none;
    border-radius: 4px 0 0 4px; } }

select {
  appearance: none; // clear Browser Appereance
  height: 40px;
  @include padding(null .5em null 1em); // 8 16
  font: {
    family: $proxima-cond;
    size: 1em;
    weight: 400; }
  line-height: 1.4;
  color: $corporate;
  border-radius: 4px;
  border: 1px solid $corporate-border-dark;
  background: {
    color: transparent;
    image: url('../Icons/icon-select.svg');
    position: right;
    repeat: no-repeat; }
  @include transition(all 0.15s ease-out);

  &:focus {
    outline: 0;
    outline: thin dotted \9;
    box-shadow: 0 0 0 0 rgba(0,0,0,0); }

  &:hover,
  &:active {
    border-color: $corporate; } }

button,
input[type="submit"] {
  display: inline-block;
  *display: inline-block;
  vertical-align: middle;
  *overflow: visible;
  @include margin(0);
  @include padding(.5em 1em); // 8 16
  font: {
    // family: $proxima-cond
    size: 1em;
    weight: 700; }
  line-height: 1.4;
  color: $corporate;
  font-style: normal;
  text-decoration: none;
  text-shadow: none;
  cursor: pointer;
  background-color: #fff;
  *background-color: #fff;
  border: none;
  border-radius: 0 4px 4px 0;
  *zoom: 1;
  box-shadow: none;
  -webkit-backface-visibility: hidden;
  @include transition(all 0.15s ease-out);

  &:focus {
    outline: 0;
    outline: thin dotted \9;
    box-shadow: 0 0 0 0 rgba(0,0,0,0); }

  &:active {
    outline: 0;
    outline: thin dotted \9; } }

button::-moz-focus-inner {
  // @include padding(0)
  border: 0; }



//  Form > Contact Form Center
// =========================================================

.form--contact {

  fieldset {
    margin-top: 1.5em; } // 24

  .input {
    // margin-bottom: .75em // 12
    margin-bottom: 1em; } // 12

  label {
    display: block;
    margin-bottom: .214285714em; // 3
    padding-left: .142857143em; // 2
    font-size: .875em; // 14

    i {
      padding-left: .142857143em; // 2
      font-style: normal;
      color: $corporate-border-dark; } }

  input[type="text"],
  input[type="email"],
  textarea {
    display: block;
    width: 100%;
    padding: .5em 1em;
    border-radius: 4px;
    border: 1px solid $corporate-border-dark;
    @include transition(all 0.15s ease-out);

    &:focus {
      border-color: $corporate; } }

  button,
  input[type="submit"] {
    @include margin(1em null null);
    color: #fff;
    background-color: $corporate;
    *background-color: $corporate;
    border-radius: 4px;

    &:hover {
      background-color: $border;
      *background-color: $border; }

    &:active {
      background-color: $corporate;
      *background-color: $corporate; } } }

.bodymed-center {

  .message {
    @include margin(1.714285714em 0); // 24
    padding: .857142857em 1.714285714em; // 12 24
    font-size: .875em; // 14
    color: $gray-text;
    line-height: 1.5;
    border-style: solid;
    border-width: 2px;
    border-radius: 4px;

    &.contact-success {
      background-color: $green-background;
      border-color: $green-dark; }

    &.contact-error {
      background-color: $red-background;
      border-color: $red; } } }


//  Form > Rest
// =========================================================

.Tx-Formhandler {

  @media only screen and (min-width: 48em) {
    max-width: 66.6666667%; }

  .form__input-group {
    flex-direction: column;
    // margin-bottom: .75em // 12
    margin-bottom: 1em; // 12

    &--checkbox {
      flex-direction: row; } }

  label {
    display: block;
    margin-bottom: .214285714em; // 3
    padding-left: .142857143em; // 2
    font-size: .875em; // 14

    i {
      padding-left: .142857143em; // 2
      font-style: normal;
      color: $corporate-border-dark; } }

  input[type="text"],
  input[type="email"],
  textarea {
    display: block;
    width: 100%;
    padding: .5em 1em;
    border-radius: 4px;
    border: 1px solid $corporate-border-dark;
    @include transition(all 0.15s ease-out);

    &:focus {
      border-color: $corporate; } }

  input[type="file"] {
    margin-bottom: .1875em; // 3
    @include padding(.5em .25em .5em 1em); // 8 4 8 16
    border-radius: 4px;
    border: 1px solid $corporate-border-dark;

    &:focus {
      border-color: $corporate; } }

  button,
  input[type="submit"] {
    @include margin(1em null null);
    color: #fff;
    background-color: $corporate;
    *background-color: $corporate;
    border-radius: 4px;

    &:hover {
      background-color: $border;
      *background-color: $border; }

    &:active {
      background-color: $corporate;
      *background-color: $corporate; } }

  .form__input-group--file {

    legend {
      display: block;
      margin-left: .142857143em; // 14
      font-size: .874em; // 14
      color: $blue-light-text; } } }

.has-error {
  color: $red;

  + input,
  + textarea {
    border-color: $red !important;
    background-color: rgba(128,31,27,.25);

    &:focus {
      border-color: $corporate !important;
      background-color: transparent; } } }

.fileupload-list {
  @include margin(0 null);

  a {
    float: right;
    @include margin(null 1em); // 16
    font-weight: 700 !important; } }

.form__input-group--checkbox {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  @include margin(null null .5em .125em); // 8 2

  & label {
    margin-bottom: 0;
    padding-left: .5em; } // 7

  & input[type="radio"],
  & input[type="checkbox"] {
    margin-top: 2px;
    flex: none; }

  + p {
    margin-top: 1.5em; } } // 24

.form__input-group--file {
  display: flex;

  & input[type="file"] {
    width: 100%;
    margin-bottom: 0;
    border-radius: 2px 0 0 2px; }

  & input[type="submit"] {
    margin-top: 0;
    border-radius: 0 2px 2px 0; }

  + legend {
    display: block;
    margin-left: .142857143em; // 14
    font-size: .874em; // 14
    color: $blue-light-text;

    + span {
      @include margin(1em null null 1.125em); } } } // 16 18



//  Form > Studie Download
// =========================================================

#studie-form {

  .form__input-group--checkbox {
    display: block;

    input[type="checkbox"] {
      display: inline-block;
      float: left;
      margin-top: .125em; // 2
      margin-right: .5em; } // 8

    label {
      padding-left: 1.571428571em; } } } // 22


//  Form > Newsletter Anmelden
// =========================================================

.form__input-group--newsletter {
  display: flex;
  flex-direction: column;

  .form__input-group {
    flex-direction: column; }

  label {
    margin-bottom: .214285714em;
    padding-left: .142857143em;
    font-size: .875em; }

  input[type="text"],
  input[type="email"] {
    display: block;
    width: 100%;
    min-width: 11.25em;
    padding: 0.5em 1em;
    border-radius: 4px;
    border: 1px solid rgba(13,69,115,.25);
    transition: all .15s ease-out;
    font: {
      family: "proxima-nova-condensed", sans-serif;
      size: 1em;
      weight: 400;
      height: 1.4;
      style: normal; }
    color: $corporate;
    vertical-align: middle;
    -webkit-appearance: none;
    box-shadow: none;
    -webkit-backface-visibility: hidden;

    &:focus {
      border-color: $corporate; } }

  input[type="text"] {
    margin-bottom: 1em; }

  input[type="submit"] {
    margin-top: 1em;
    color: #fff;
    background-color: #0d4573;
    border-radius: 4px;

    + legend {
      display: block;
      margin-top: .571428571em; // 8
      margin-left: .142857143em; // 14
      font-size: .875em; // 14
      color: $blue-light-text; } } }


//  Form > Newsletter Anmelden
// =========================================================

.download__practice--form {

  input {

    &#newsletter {
      margin-bottom: .5em; // 8

      @media only screen and (min-width: 64em) {
        max-width: 75%; } }

    &[type="submit"] {
      display: block;
      margin-top: 1.5em; } } } // 24
