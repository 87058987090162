// Footer > Credits
// =========================================================

.footer__credits {
  padding-top: 2em; // 32
  border-top: 1px solid #fff;

  ul {
    display: block;
    text-align: center; }

  li {
    display: inline-block;
    @include margin(0 .5em 2em); // 8 32

    @media only screen and (min-width: 48em) {
      @include margin(null 1em); } } // 16

  img {
    max-height: 2em; // 32

    @media only screen and (min-width: 30em) {
      max-height: 2.5em; } // 40

    @media only screen and (min-width: 48em) {
      max-height: 3em; } // 48

    @media only screen and (min-width: 64em) {
      max-height: 4em; } } } // 64
