
// overrides styles for IE only

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

  .m-gr--1of2 {

    &.gr--g > .gc {
      padding: 0; // standard grid cell gutters

      > * {
        margin: 1em 0 0 1em; } } // standard grid cell gutters

    &.gr--g-l > .gc {
      padding: 0; // large grid cell gutters

      > * {
        margin: 1.5em 0 0 1.5em; } }


    &.gr--g-xl > .gc {
      padding: 0; // huge grid cell gutters

      > * {
        margin: 2em 0 0 2em; } } }


  @media only screen and (min-width: 30em) {

    .m-gr--1of2 {
      &.s-gr--g > .gc {
        padding: 0;

        > * {
          margin: 1em 0 0 1em; } }

      &.s-gr--g-l > .gc {
        padding: 0;

        > * {
          margin: 1.5em 0 0 1.5em; } }

      &.s-gr--g-xl > .gc {
        padding: 0;

        > * {
          margin: 2em 0 0 2em; } } } }

  @media only screen and (min-width: 48em) {

    .m-gr--1of2 {
      &.m-gr--g > .gc {
        padding: 0;

        > * {
          margin: 1em 0 0 1em; } }

      &.m-gr--g-l > .gc {
        padding: 0;

        > * {
          margin: 1.5em 0 0 1.5em; } }

      &.m-gr--g-xl > .gc {
        padding: 0;

        > * {
          margin: 2em 0 0 2em; } } } }

  @media only screen and (min-width: 64em) {

    .m-gr--1of2 {
      &.l-gr--g > .gc {
        padding: 0;

        > * {
          margin: 1em 0 0 1em; } }

      &.l-gr--g-l > .gc {
        padding: 0;

        > * {
          margin: 1.5em 0 0 1.5em; } }

      &.l-gr--g-xl > .gc {
        padding: 0;

        > * {
          margin: 2em 0 0 2em; } } } } }
