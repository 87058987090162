// Content Module XX: News Overview Home
// =========================================================

.news__mod {
  margin-bottom: 1.5em;

  .news__mod--list {

    figure {
      position: relative;
      width: 100%;
      border-radius: 0.25em 0.5em 0.5em 0.5em;
      border: 1px solid rgba(13, 69, 115, 0.25);
      overflow: hidden;

      &.u-image-noborder {
        border-radius: 0;
        border: none;
        border-color: transparent; }

      a {
        display: block; }

      img {
        height: auto; }

      &:hover {

        > figcaption {

          a {

            &:hover {
              color: $green !important; } } } } }


    .no-media-element {
      display: none; }

    h2 {
      position: absolute;
      top: .266666667em; // 8px
      left: .666666667em; // 20px
      color: #fff; }

    figcaption {

      a {
        @include padding(.72em); // 18px
        font: {
          family: $proxima-cond;
          size: 1.5625em; // 25px
          weight: 600; }
        color: $corporate; } } } }



// Content Module XX: News Overview Magazin
// =========================================================

#layout__2columns {

  .news__mod--list,
  .news__mod {

    .article {
      margin-bottom: 1.5em; // 24

      &:last-of-type {
        margin-bottom: 0; }

      .float__left {

        @media only screen and (min-width: 30em) {
          width: 100%; }

        @media only screen and (min-width: 48.0625em) {
          max-width: 50%; }

        @media only screen and (min-width: 64em) {
          max-width: 40%; } }

      .news--category {
        margin-bottom: .833333333em; // 10
        font-size: 1em; // 16
        // color: $corporate-border-dark
        color: $blue-light-text; }

      h3 {
        margin-bottom: .48em; } // 12

      p {

        + .btn {
          margin-top: .75em; } } // 12

      + hr {

        @media only screen and (min-width: 48em) {
          margin-bottom: 2.5em; } } } // 40

    hr:last-of-type {
      display: none; } } }
