// Content Global Setting
// =========================================================

#layout__1column,
#layout__2columns,
#layout__center {

  .content {
    @include padding(3em null 4em);
    flex: 1;

    @media only screen and (max-width: 47.9375em) {
      padding-left: 0; }

    @media only screen and (min-width: 48em) {
      padding-right: 2em;
      padding-left: 2em; } } }

.content {

  .stage__static--maps {
    border-radius: 0.25em 0.5em 0.5em 0.5em; // 4 8
    box-shadow: 1px 1px 3px rgba(0,2,6,.1);
    border: 1px solid $corporate-border; } }



// Content Module: Global Setting
// =========================================================

.content__mod {
  margin-bottom: 1.5em;

  &:last-of-type {
    margin-bottom: 0; }

  &:before,
  &:after {
    content: " ";
    display: table; }

  &:after {
    clear: both; }

  &--expand {
    @include margin(null -0.625em); // -10px
    @include padding(1em 0.625em); // 16px 10px

    @media only screen and (min-width: 30em) {
      @include margin(null -2.125em); // -34px
      @include padding(1.625em 2.125em); } } // 26px 34px

  + .Tx-Formhandler {
    margin-top: 1.5em; }

  + .news__mod {
    @include margin(4em null 3em); } } // 64 48


// Content Module: Overarching Stylings
// =========================================================

.content__mod {

  &--cta-dl,
  &--link-block {
    @include padding(1em); // 16
    box-shadow: 1px 1px 3px rgba(0,2,6,.1);
    border: 1px solid $corporate-border;
    border-radius: .25em .5em .5em .5em; // 4 8
    background-color: $blue-light;
    overflow: hidden;

    @media only screen and (min-width: 48em) {
      @include padding(1.625em); } } } // 26px
