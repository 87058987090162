// Colours
// =========================================================

// $black: #212121

$gray-text: #2e2e2e;

$corporate: #0d4573; // Bodymed Blue

$corporate-border: rgba(13,69,115,.1);
$corporate-border-dark: rgba(13,69,115,.25);

$green: #97be0d; // Bodymed Green
$green-light: #aed914; // Hover & Active Colour
$green-dark: #84a60b; // Hover & Active Colour
$green-background: #c2e5b7;

$red: #c32623; // Form Warnings
$red-background: #f5c8d7; // Form Warnings Background

$background: #e8eff7; // Light blue close to white

$blue-light: #e4f0f9;
$blue-light-text: #86a2b9;
$blue-lighter: #f2f9ff;
$blue-medium: #d8e6f1;

$border: #517694; // blueish, used as btn border-color
$border-green: #8db10c;



// Fonts
// =========================================================

$proxima: "proxima-nova", sans-serif; // 300, 400, 600, 700
$proxima-cond: "proxima-nova-condensed", sans-serif; // 300, 400, 600
