// Footer > Regions
// =========================================================

.footer__regions {

  @media only screen and (min-width: 75em) {
    background: {
      image: url('../Images/img-map-europa.png');
      position: center right;
      size: auto auto;
      repeat: no-repeat; } }

  .inside {
    margin: {
      left: auto;
      right: auto; }

    > .gc {
      padding-right: 1em;

      @media only screen and (min-width: 48em) {

        padding-right: 2em;
        &:not(:last-of-type) {
          padding-right: 0; } } } }

  p {
    padding-bottom: .25em; // 4
    font-weight: 700;
    color: $corporate;
    border-bottom: 1px solid $corporate; }

  ul {
    margin-top: 1em; // 16

    + p {
      margin-top: 4.1875em; } } // 67

  &--germany {

    ul {
      @include columns(2 auto);
      @include column-gap(2em); } } } // 32
