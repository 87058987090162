// Nav > Region
// =========================================================

.nav__region {
  margin-bottom: 1.5em; // 24

  @media only screen and (min-width: 48em) {
    margin-bottom: 0; }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3em; // 48
    font-size: .875em; // 14

    @media only screen and (min-width: 48em) {
      justify-content: flex-start;
      margin-top: 0; } }

  li {

    &:after {
      content: ">";
      margin: 0 .5em; } // 7

    &:first-child {
      width: 100%;
      font-weight: 600;

      &:after {
        content: "\00a0 ";
        margin: 0; } }

    &:last-child {

      &:after {
        display: none; } } } }

.footer {

  > .gr {

    > .u-full {

      @media only screen and (min-width: 48em) {
        display: flex;
        justify-content: space-between;
        align-items: flex-end; } } } }
