//  Typography
// =========================================================

h1,
.h1,
h2,
h3,
h4 {
  font-family: $proxima-cond;
  line-height: 1.2; }

h1, .h1 {
  margin: {
    top: 0;
    bottom: .5em; } // 14
  font: {
    size: 2.5em; // 40
    weight: 300; }

  @media only screen and (min-width: 30em) {
    margin-bottom: .4em; // 16
    font-size: 2.5em; } } // 40

h2 {
  margin: {
    top: 0;
    bottom: .642857143em; } // 19
  font: {
    size: 1.875em; // 30
    weight: 600; }
  text-transform: uppercase; }

h3 {
  margin: {
    top: 0;
    bottom: 1em; } // 25
  font: {
    size: 1.5625em; // 25
    weight: 600; } }

h4 {
  margin: {
    top: 0;
    bottom: 0; }
  font: {
    size: 1.25em; // 20
    weight: 600; } }

p {
  margin: {
    top: 0;
    bottom: 1.5em; } // 24
  font: {
    size: 1em; }

  &:last-of-type {
    margin-bottom: 0; }

  + .btn {
    margin-top: 1.263157895em; } } // 24

b,
strong {
  font-weight: 700; }

em {
  font-style: normal; }

blockquote {
  display: block;
  position: relative; }

address {

  .org {
    font-weight: 700; } }

dl {
  margin: 0; }

dt,
dd {
  display: inline;
  width: 50%;
  margin: 0;
  padding: {
    right: .25em; // 4
    left: .25em; } // 4
  border-bottom: 1px solid $blue-light;

  &:last-of-type {
    border-bottom: none; } }

dd {
  text-align: right; }

hr {
  display: block;
  height: 1px;
  @include margin(1.5em 0); // 24
  padding: 0;
  border: 0; }

