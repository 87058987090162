// Center Team
// =========================================================

.employee__card {

  figure {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 30em) {
      flex-direction: row; }

    img {
      border-radius: 0.25em 0.5em 0.5em;
      display: block;
      max-width: 7.5em; // 120
      margin: {
        right: auto;
        left: auto; } }

    > div {
      min-width: 7.5em; // 120

      @media only screen and (min-width: 64em) {
        width: 35%; } }

    > figcaption {

      @media only screen and (min-width: 64em) {
        width: 65%; } } }

  figcaption {
    padding-top: 1em;
    text-align: center; }

  @media only screen and (min-width: 30em) {
    padding: {
      top: 0;
      left: 1em; }
    text-align: left; }

  h3 {
    margin-bottom: .24em; } // 6

  p,
  p + a {
    font-size: .875em; } // 14

  a[href^="mailto:"] {
    display: block;
    margin-top: .5em; // 7
    color: $green-dark; }

  @media only screen and (min-width: 30em) {

    figcaption {
      padding: {
        top: 0;
        left: 1em; }
      text-align: left; } } }

.employee__role {
  margin-bottom: 0;
  font-weight: 700; }
