// Box Layout
// =========================================================

.box {

  address {
    margin-bottom: .75em;
    font-style: normal;

    + div {
      display: flex;
      justify-content: space-between;
      align-items: center; }

    p {
      margin-bottom: 0; } } }

.box__height--var {
  min-height: 1px; }

.box__bordered {
  box-shadow: 1px 1px 3px rgba(0,2,6,0.1);
  border: 1px solid $corporate-border;
  background: {
    color: #ffffff; } }

.box__bg {
  box-shadow: 1px 1px 3px rgba(0,2,6,0.1);
  border: 1px solid $corporate-border;
  background: {
    color: $blue-light; } }

.box__bg--testimonial {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: {
    image: url(/assets/images/bg-img-teaser-testimonial.jpg);
    size: cover;
    position: top right;
    repeat: no-repeat; }

  h3 {
    width: 60%; } }

.box__bg--gradient {
  background-image: linear-gradient(to top, rgba(232,242,247,1) 0%, rgba(244,252,240,1) 100%); }

.box__bg--light {
  background-color: $blue-lighter; }

.box__border--dark {
  border: 1px solid $corporate-border-dark; }



// Google Maps
// =========================================================



.gmaps__center {
  margin-bottom: 2em; }



// Center Detail Nav
// =========================================================



.center__nav {
  display: flex;
  justify-content: space-between;
  align-items: center; }



// Accordion
// =========================================================

.accordion {

  ul {
    @include margin(0);
    @include padding(0);
    list-style-type: none; }

  h3 {
    margin-bottom: 0; }

  i {
    position: absolute;
    transform: translate( -6px , 0 );
    right: 2em;
    top: 40%;

    &:before , &:after {
      content: "";
      position: absolute;
      background-color: $corporate;
      width: 2px;
      height: 16px; }

    &:before {
      transform: translate( -5px , 0 ) rotate( 45deg ); }

    &:after {
      transform: translate( 5px , 0 ) rotate( -45deg ); } }

  label {
    display: block;
    overflow: hidden; }

  .accordion__title {
    position: relative;
    min-height: 4.5em;
    margin-bottom: 1.125em;
    @include padding(1.25em 1.5em);
    z-index: 599;
    // @include transition(all .3s linear)

    &:hover {
      cursor: pointer; } }

  .accordion__content {
    display: none;
    position: relative;
    @include margin(1.375em 1.5em 2.5em);
    max-height: 0px;
 }    // @include transition(max-height .3s linear)

  input[type="checkbox"] {
    display: none; }

  input[type="checkbox"]:checked {

    + .accordion__title > i {
      &:before {
        transform: translate( 5px , 0 ) rotate( 45deg ); }

      &:after {
        transform: translate( -5px , 0 ) rotate( -45deg ); } } }

  input[type="checkbox"]:checked + .accordion__title + .accordion__content {
    display: block;
    max-height: 100%; } }

.accordion__calendar,
.accordion__news {

  .accordion__title {
    display: flex;
    flex-direction: column;

    .date {
      font-weight: 700; } }

  @media only screen and (min-width: 48em) {

    .accordion__title {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      time {
        text-align: right; }

      .date {
        display: block; } } } }
