//  Tables
// =========================================================

table {
  width: 100%;
  margin: {
    top: 1.857142857em; // 36
    bottom: 1.857142857em; } // 36
  font-size: .875em; // 14
  table-layout: fixed;

  thead {

    tr {
      border-bottom: 2px solid $corporate-border; } }

  th,
  td {
    @include padding(.142857143em .285714286em); // 2 4
    vertical-align: top; }

  th {
    font-weight: 700;
    text-align: left; }

  tbody {

    tr {

      &:nth-child(even) {
        background-color: $blue-lighter; } } } }
