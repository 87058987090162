// 1 Column Page Layout
// =========================================================

#layout__1column,
#layout__center,
.stage__static--intro {
  display: flex;
  max-width: 75em; // 1200px
  margin: 0 auto;
  // margin: 0 auto 3.75em // 0 60px
  padding: 0 .625em; // 10px
  background-color: #fff;

  @media only screen and (min-width: 64em) {
    padding: 0 3em; } // 48px

  @media only screen and (min-width: 75em) {
    padding: 0 7.5em; } // 120px


  // Moved to content-mod-global.sass
  // .content
  //   padding-bottom: 1.5em

  //   @media only screen and (max-width: 47.9375em)
  //     padding-left: 0

  //   @media only screen and (min-width: 48em)
  //     // margin-right: -1.5em
 }  //     padding-right: 1.5em
