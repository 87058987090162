// Navigation > Prinzipienbox
// =========================================================

.slick-dots {
  display: flex;
  padding: 0;
  background-color: $blue-medium;
  list-style-type: none;

  li {
    display: flex;
    flex: 1;
    font: {
      family: $proxima-cond;
      size: 1.25em; // 20px
      wight: 700; }
    text-align: center;
    border-right: 1px solid #fff;

    &:first-child {
      display: none; }

    &:last-child {
      border-right: none;

      span {

        &:after {
          content: " ";
          margin-left: 0; } } }

    &.slick-active {

      button {
        color: #fff;
        background-color: $green; } }

    span {

      &:last-of-type {
        display: none;

        @media only screen and (min-width: 48em) {
          display: block; } }

      &:first-of-type {
        display: block;

        @media only screen and (min-width: 48em) {
          display: none; } } } }

  button {
    display: block;
    width: 100%;
    @include padding(1em .571428571em); // 14px 8px
    font-size: .7em; // 14px
    text-align: center;
    border-radius: 0;
    background-color: $blue-medium;
    @include transition(all 0.15s ease-out);

    @media only screen and (min-width: 30em) {
      font-size: .7em; } // 14px

    @media only screen and (min-width: 48em) {
      @include padding(1em .5em); // 20px 10px
      font-size: 1em; } // 20px

    &:hover {
      color: #fff;
      background-color: $green-light; }

    &:active, {
      color: #fff;
      background-color: $green; } } }
