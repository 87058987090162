// Center
// =========================================================

.center__card {

  ul {
    display: flex;
    justify-content: space-between;
    @include margin(0);
    @include padding(12px 0 0);
    border-top: 1px solid $corporate-border; }

  li {
    list-style-type: none;

    &:first-child {
      text-align: left; }

    &:nth-child(2) {
      @include padding(null .25em);
      font-size: 1.5em;
      line-height: 1;
      color: $corporate;
      @include transition(all 0.15s ease-out);

      &:after {
        content: "\2606";
        color: inherit; }

      &.is-selected {

        &:after {
          content: "\2605"; } }

      &:hover {
        color: $green;
        cursor: pointer; } }

    &:last-child {
      text-align: right; } }

  a {
    font-weight: 700 !important;
    color: $corporate !important;

    &:hover {
      color: $green !important; } }

  ul {

    a {

      &:before {
        content: ">";
        margin-right: .5em; // 8
        font-weight: inherit;
        color: inherit; } } } }

// .content__mod--map

//   .center__card

//     li
//       list-style-type: none

//       &:first-child
//         text-align: left

//    // &:nth-child(2)
//    //   visibility: hidden

//    //   &:after
//    //     display: none

.box__centers {

  .form__input-group {
    width: 100%;
    margin-top: 1.5em;

    input[type="search"] {
      min-width: auto;
      width: 100%; }

    .btn {
      border-radius: 0 4px 4px 0;
      white-space: nowrap;

      @media only screen and (max-width: 56.25em) { // 900
        font-size: 0; }

      @media only screen and (min-width: 56.3125em) { // 901
        min-width: 40%; }

      // @media only screen and (max-width: 48em)
      //   font-size: 1.1875em

      @media only screen and (max-width: 30em) {
        font-size: 0; }

      &:before {

        @media only screen and (max-width: 56.25em) { // 900
          font-size: 19px;
          margin-left: 0.736842105em; }

        // @media only screen and (max-width: 30em)
        //   font-size: 19px
 } } } }        //   margin-left: 0.736842105em
