// Footer Global
// =========================================================

footer {

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    a {
      font-weight: 400; } } }

.footer {

  &__newsletter,
  &__main,
  &__regions {
    padding: {
      top: 2em; // 32
      bottom: 2em; } } } // 32
