// Layout > Global
// =========================================================

.inside {
  max-width: 75em; // 1200px
  margin: {
    left: auto;
    right: auto; }
  padding: {
    right: .625em;
    left: .625em; } }

.expand {
  max-width: 100%;
  padding: {
    left: 0;
    right: 0; } }
