// Sidebar Basic
// =========================================================

.sidebar {
  display: none;
  padding-top: 2em !important; // override Flexbox Grid Cell
  padding-bottom: 2em !important; // override Flexbox Grid Cell
  border-left: 1px solid $corporate-border;

  @media only screen and (min-width: 48em) {
    display: block; }

  > h4 {
    @include padding(.3em .4em .8em); } } // 6 8 16
