// Navigation > Main
// =========================================================

.nav__header--main {
  display: none;

  @media only screen and (min-width: 48em) {
    display: block; }

  ul {
    display: flex;
    font-family: $proxima-cond;

    @media only screen and (min-width: 48em) {
      font-size: 1.125em; } // 18

    @media only screen and (min-width: 64em) {
      font-size: 1.25em; } } // 20

  li {
    margin-left: 1em; // 16

    @media only screen and (min-width: 48em) {
      margin-left: 1em; } // 16

    @media only screen and (min-width: 64em) {
      margin-left: 1.8em; } // 16

    &:first-child {
      margin-left: 0; }

    &.active {

      a {
        color: $green; } } }

  a {
    font-weight: 400; } }
