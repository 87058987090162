// Nav Center
// =========================================================

.nav__center {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  min-height: 1px;
  @include margin(0 auto);
  background-color: $blue-medium;
  box-shadow: 0 -1px 3px rgba(0,0,0,.1);
  z-index: 1000;

  > .inside {
    display: flex;
    justify-content: center;
    @include padding(null 0);

    @media only screen and (min-width: 30em) {
      justify-content: space-between; } }

  ul {
    display: flex;
    font-family: $proxima-cond; }

  &--main {

    li {

      @media only screen and (min-width: 30em) {
        border-right: 1px solid #fff; }

      &:first-child {
        border-left: none; }

      &.active {
        background-color: $green-light;

        > a {
          color: #fff; } } }

    a {
      display: block;
      @include padding(.642857143em .5em); // 9 7
      font-size: .875em;
      color: $corporate;

      @media only screen and (min-width: 30em) {
        @include padding(null .714285714em); } // 10

      @media only screen and (min-width: 48em) {
        @include padding(null 1em); }

      @media only screen and (min-width: 64em) {
        @include padding(.333333333em 1em); // 6 18
        font-size: 1.125em; } // 18

      &:hover {
        color: #fff;
        background-color: $green-light; } } }

  &--social {
    display: none;

    @media only screen and (min-width: 30em) {
      display: block; }

    li {
      @include padding(.1875em .125em .25em); // 4 2 3

      @media only screen and (min-width: 48em) {
        @include padding(.1875em .5em .25em); } } // 4 8 3

    img {
      height: 32px;
      width: 32px; }

    a {
      display: block;
      opacity: .75;

      &:hover {
        opacity: 1; } } } }
