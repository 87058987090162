// Bodymed Backend
// =========================================================

.bodymed-backend {

  .header {

    position: relative;

    &__main {
      padding-top: .75em;
      padding-bottom: .25em; } }

  .logo {

    img {
      max-width: 50%; } }

  .stage__static--content {

    @media only screen and (min-width: 48em) {
      // min-height: 26.75em // 428 px
      min-height: 1px; }

    @media only screen and (min-width: 64em) {
      @include padding(2em); } // 36

    .checkmarks {
      @include margin(null null 1.5em null); // 24px
      @include padding(null null null .375em); // 6px

      li {
        background: url('../Icons/icon-checkmark.png') no-repeat left top;
        margin-bottom: .25em; // 4px
        padding-left: 2em; //32px

        &:last-child {
          margin-bottom: 0; } } } }

  select {
    font-family: $proxima; }

  .pagination {
    padding-left: 0;
    text-align: center;

    li {
      @include padding(null .5em);

      &.active {

        a {
          color: $corporate; } } }

    .prev {
      margin-right: 1em; }

    .next {
      margin-left: 1em; }

    // delete if only needed for development
    + p {
      color: $border;
      font-size: .875em;
      text-align: center; } }

  table {

    th {
      padding-right: .285714286em; // 4px
      padding-left: .285714286em; // 4px

      a {
        // display: block
        padding-left: .857142857em; // 12
        font-weight: 700 !important;
        background: {
          image: url('../Icons/icon-select-order.svg');
          position: left;
          repeat: no-repeat; }

        &:hover {
          background-image: url('../Icons/icon-select-order-hover.svg'); } } } }

  .Tx-Formhandler {
    max-width: 100%;

    @media only screen and (min-width: 48em) {
      max-width: 100%; } } }


.social-inputs {

  .input {

    &.text {
      display: flex;
      align-items: center;
      margin-top: 3em; // 48

      &:first-of-type {
        margin-top: 1.5em; } // 24


      label {
        width: 40%;
        padding-right: .5em; // 7
        text-align: right; }

      input {
        width: 60%; } }

    &.checkbox {

      label {
        margin-left: 40%;

        input {
          margin-right: .5em; } } } } } // 7
