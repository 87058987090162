.mm-hidden {
  display: none !important; }

.mm-wrapper {
  overflow-x: hidden;
  position: relative; }

.mm-menu,
.mm-menu > .mm-panel {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  @include margin(0);
  z-index: 0; }

.mm-menu {
  display: block;
  @include padding(0);
  background: inherit;
  overflow: hidden; }

.mm-panel {
  transition: transform 0.4s ease;
  transform: translate3d(100%,0,0);

  &.mm-opened {
    transform: translate3d(0%,0,0); }

  &.mm-subopened {
    transform: translate3d(-30%,0,0); }

  &.mm-highest {
    z-index: 1; } }

.mm-menu {

  > .mm-panel {
    @include padding(0 20px);
    border-color: inherit;
    background: inherit;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }

  > .mm-panel.mm-hasnavbar {
    padding-top: 40px; }

  > .mm-panel {

    &:before,
    &:after {
      content: " ";
      display: block;
      height: 20px; } } }

.mm-navbar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 40px;
  @include margin(0);
  @include padding(0 40px);
  text-align: center;
  line-height: 20px;
  border-bottom: 1px solid;
  border-color: inherit;

  > * {
    display: block;
    padding: 10px 0; }

  a,
  a:hover {
    text-decoration: none; }

  .mm-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; } }

.mm-navbar {

  .mm-btn {
    position: absolute;
    top: 0;
    width: 40px;
    height: 40px;
    z-index: 1;

    &:first-child {
      left: 0;
      padding-left: 20px; }

    &:last-child {
      right: 0;
      padding-right: 20px;
      text-align: right; } } }

.mm-panel {

  .mm-navbar {
    display: none; }

  &.mm-hasnavbar {

    .mm-navbar {
      display: block; } } }

.mm-listview,
.mm-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0; }

.mm-listview {
  font: inherit;
  font-size: 14px;
  line-height: 20px;

  a,
  a:hover {
    text-decoration: none; }

  > li {
    position: relative;
    border-color: inherit;

    &:after,
    .mm-next,
    .mm-next:before {
      border-color: inherit; }

    > a,
    > span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: $corporate;
      display: block;
      padding: 10px 10px 10px 20px;
      margin: 0; } }

  > li {

    > a.mm-arrow,
    > span.mm-arrow {
      padding-right: 50px; }

    &:not(.mm-divider):after {
      content: " ";
      border-bottom-width: 1px;
      border-bottom-style: solid;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0; }

    &:not(.mm-divider):after {
      left: 20px; } }

  .mm-next {
    background: rgba(3,2,1,0);
    width: 50px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;

    &:before {
      content: " ";
      border-left-width: 1px;
      border-left-style: solid;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0; }

    + a,
    + span {
      margin-right: 50px; }

    &.mm-fullsubopen {
      width: 100%;

      &:before {
        border-left: none; }

      + a,
      + span {
        padding-right: 50px;
        margin-right: 0; } } } }

.mm-menu {

  > .mm-panel {

    > .mm-listview {
      margin: 20px -20px; }

    > .mm-listview:first-child,
    > .mm-navbar + .mm-listview {
      margin-top: -20px; } } }

.mm-listview {

  .mm-divider {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 10px;
    text-transform: uppercase;
    text-indent: 20px;
    line-height: 25px; } }

.mm-prev:before,
.mm-next:after {
  content: " ";
  border: 2px solid transparent;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  transform: rotate(-45deg); }

.mm-prev:before {
  border-right: none;
  border-bottom: none;
  left: 20px; }

.mm-next:after {
  border-top: none;
  border-left: none;
  right: 20px; }

.mm-menu {
  background: $blue-medium;
  border-color: rgba(0,0,0,.1);
  color: rgba(0,0,0,.7);

  .mm-navbar {

    > *,
    a {
      color: $corporate-border-dark; }

    .mm-btn:before,
    .mm-btn:after {
      border-color: $corporate-border-dark; } }

  .mm-listview {
    border-color: rgba(0,0,0,.1);

    > li {

      .mm-next {

        &:after {
          border-color: $corporate-border-dark; } }

      &.mm-selected {

        > a:not(.mm-next),
        > span {
          background: rgba(255,255,255,.5); } } } } }

.mm-menu.mm-vertical .mm-listview > li.mm-opened > a.mm-next,
.mm-menu.mm-vertical .mm-listview > li.mm-opened > .mm-panel,
.mm-menu .mm-listview > li.mm-opened.mm-vertical > a.mm-next,
.mm-menu .mm-listview > li.mm-opened.mm-vertical > .mm-panel {
  background: rgba(0,0,0,.05); }

.mm-menu {

  .mm-divider {
    background: rgba(0,0,0,.05); } }

.mm-page {
  box-sizing: border-box;
  position: relative; }

.mm-slideout {
  transition: transform .4s ease; }

html {

  &.mm-opened {
    overflow: hidden;
    position: relative;

    body {
      overflow: hidden; } } }

html {

  &.mm-background {

    .mm-page {
      background: inherit; } } }

#mm-blocker {
  background: rgba(3,2,1,0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999; }

html {

  &.mm-opened,
  &.mm-blocking {

    #mm-blocker {
      display: block; } } }

.mm-menu {

  &.mm-offcanvas {
    display: none;
    position: fixed; }

  &.mm-current {
    display: block; } }

.mm-menu {
  width: 80%;
  min-width: 140px;
  max-width: 440px; }

html {

  &.mm-opening {

    .mm-slideout {
      transform: translate(80%,0);

      @media all and (max-width: 175px) {
        transform: translate(140px,0); }

      @media all and (min-width: 550px) {
        transform: translate(440px,0); } } } }

.mm-menu {

  &.mm-top,
  &.mm-bottom {
    width: 100%;
    min-width: 100%;
    max-width: 100%; } }

.mm-menu {

  &.mm-right {
    left: auto;
    right: 0; } }

.mm-menu {

  &.mm-bottom {
    top: auto;
    bottom: 0; } }

html {

  &.mm-right {

    &.mm-opening {

      .mm-slideout {
        transform: translate(-80%,0);

        @media all and (max-width: 175px) {
          transform: translate(-140px,0); }

        @media all and (min-width: 550px) {
          transform: translate(-440px,0); } } } } }

html {

  &.mm-front {

    .mm-slideout {
      transform: none !important;
      z-index: 0 !important; } } }

.mm-menu {

  &.mm-front {
    z-index: 1; } }

.mm-menu {

  &.mm-front,
  &.mm-next {
    transition: transform 0.4s ease;
    transform: translate3d(-100%,0,0);

    &.mm-right {
      transform: translate3d(100%,0,0); } }

  &.mm-top {
    transform: translate3d(0,-100%,0); }

  &.mm-bottom {
    transform: translate3d(0,100%,0); } }

html {

  &.mm-opening {

    .mm-menu {

      &.mm-front,
      &.mm-next {
        transform: translate3d(0,0,0); } } } }

.mm-menu {

  &.mm-top,
  &.mm-bottom {
    height: 80%;
    min-height: 140px;
    max-height: 880px; } }


// Overrides
// ------------------------------------------

.mm-menu {

  .active {

    a {
      color: $green; } }

  &.mm-opened {
    box-shadow: 0 2px 6px rgba(0,0,0,.35); } }
