// Content Module: Blockquote
// =========================================================

.content__mod--blockquote {

  blockquote {
    @include margin(0 1em);

    @media only screen and (min-width: 30em) {
      @include margin(null 3em); }

    @media only screen and (min-width: 48em) {
      @include margin(null 4.5em); }

    &:before {
      content: "„";
      position: absolute;
      top: -.171875em;
      left: 50%;
      margin-left: -.1875em;
      font: {
        size: 7em; // 126px
        weight: 600; }
      line-height: 0;
      color: $blue-medium;

      @media only screen and (min-width: 30em) {
        top: -.2em;
        left: -.444444444em;
        margin-left: 0; }

      @media only screen and (min-width: 48em) {
        top: -.2em;
        left: -.6em; } } }

  p {
    padding-top: 2em;
    font: {
      size: 1.125em; // 18px
      weight: 600; }
    text-align: center;

    @media only screen and (min-width: 30em) {
      padding-top: 1em;
      text-align: left; }

    @media only screen and (min-width: 48em) {
      padding-top: .5em; } } }
