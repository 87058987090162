// Footer > Newsletter
// =========================================================

.footer__newsletter {

  .inside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media only screen and (min-width: 48em) {
      flex-direction: row; } }

  .btn {
    text-align: center;
    border: 1px solid $blue-medium;

    &:before {
      display: none; }

    span {
      display: block;

      &:first-of-type {
        text-transform: uppercase; }

      &:last-of-type {
        font: {
          size: .736842105em; // 14
          weight: 400; } } }

    &:hover,
    &:focus {

      span {
        color: $corporate; } }

    &:active {

      span {
        color: $blue-medium; } } } }
