// Navigation > Onpage
// =========================================================

.nav__onpage {

  ul {

    @media only all and (min-width: 30em) {
      columns: 2 auto;
      column-gap: 1.5em; }

    @media only all and (min-width: 48em) {
      columns: 3 auto; }

    li {
      break-inside: avoid; } } }

.content {

  .nav__onpage {

    li {

      @media only screen and (min-width: 48em) {
        font-size: .875em; // 14px
        line-height: 1.714285714; } } // 24px

    a {
      color: $corporate;

      &:hover {
        color: $green; } } } }
