// Text Helpers
// =========================================================

.text {

  &--centered,
  &--center {
    text-align: center; }

  &--left {
    text-align: left; }

  &--right {
    text-align: right; }

  &__columnize--2 {

    @media only screen and (min-width: 48em) {
      columns: 2 auto;
      column-gap: 2em; } } }



// Background Colours
// =========================================================

.bg__blue {
  background-color: $corporate;

  &--light {
    background-color: $blue-light; }

  &--lighter {
    background-color: $blue-lighter; } }



// Media Helpers
// =========================================================

.u-img-autoheight {
  max-width: 100%;
  height: auto; }



// Spacings
// =========================================================

.u {

  &-margin {

    &-top {
      margin-top: 1em;

      &-big {
        margin-top: 2em; } } } }

.no {

  &-margin {
    @include margin(0); }

  &-margin--bottom {
    margin-bottom: 0; } }

.neg-margin--top {
  margin-top: -1em; }

section {

  &.spacing__vertical {

    &--big {
      padding: {
        top: 4em;
        bottom: 4em; } }

    &--none {
      padding: {
        top: 0;
        bottom: 0; } } } }



// Floats
// =========================================================

// !! USE FOR IMAGES ONLY !!

.float {

  &__right,
  &__left,
  &__center,
  &__center--left,
  &__center--right {
    display: block;
    margin-bottom: 1em; } }

@media only screen and (min-width: 30em) {

  .float {

    &__right {
      float: right;
      max-width: 50%;
      margin-left: 1.5em; }

    &__left {
      float: left;
      max-width: 50%;
      margin-right: 1.5em; } } }

@media only screen and (min-width: 48em) {

  .float {

    &__right,
    &__left {
      max-width: 29.375em; } } }

.float__center,
.float__center--left,
.float__center--right {
  float: center;
  margin: {
    left: auto;
    right: auto; } }

@media only screen and (min-width: 48em) {

  .float__center--left {
    float: left;
    margin: {
      right: 1.5em;
      left: 0; } }

  .float__center--right {
    float: right;
    margin: {
      right: 0;
      left: 1.5em; } } }



// Visibility
// =========================================================

.hidden {
  display: none !important; }

.invisible {
  visibility: hidden; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }



