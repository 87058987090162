//  Media Resets
// =========================================================

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; } // Remove the gap between images, videos, audio and canvas and the bottom of their containers

figure {
  @include margin(0); }



//  Images
// =========================================================

img {
  max-width: 100%; }



// Responsive Videos
// =========================================================

.responsive-video {
  position: relative;
  height: 0;
  max-width: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;

  video,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }

