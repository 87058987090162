// Content Module: Download CTA
// =========================================================

.content__mod--cta-dl {

  h3 {
    @include margin(3.36em null .24em); // 84 6

    @media only screen and (min-width: 48em) {
      margin-top: 0;
      padding-left: 3.36em; } } // 84

  h3,
  p {
    text-align: center;

    @media only screen and (min-width: 48em) {
      text-align: left; } }

  .u-textblock {
    background: {
      image: url('../Icons/_icon-download.png');
      position: center 1em;
      repeat: no-repeat; }

    @media only screen and (min-width: 48em) {
      background-position: 1em 1em; }

    p {

      @media only screen and (min-width: 48em) {
        padding-left: 5.25em; } } } } // 84
