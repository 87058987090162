// Content Module: Team Card
// =========================================================

.team__card {

  figure {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 30em) {
      flex-direction: row; }

    > div {

      @media only screen and (min-width: 64em) {
        width: 35%; } }

    > figcaption {

      @media only screen and (min-width: 64em) {
        width: 65%; } } }

  img {
    border-radius: 0.25em 0.5em 0.5em;
    display: block;
    max-width: 5.625em; // 90
    height: auto;
    margin: {
      right: auto;
      left: auto; } }

  figcaption {
    padding-top: 1em;
    text-align: center;
    font-size: .875em;

    @media only screen and (min-width: 30em) {
      padding: {
        top: 0;
        left: 1em; }
      text-align: left; } }

  p,
  p + a {
    margin-bottom: 0; }

  .team__card--role {
    margin-bottom: .5em;
    font-weight: 700;

    @media only screen and (min-width: 48em) {
      margin-top: 1em; } } }
