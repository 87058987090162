// Basic Styles
// =========================================================

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-rendering: optimizelegibility;
  background-color: #fff; }

body {
  font: {
    family: $proxima;
    size: 1em;
    weight: 400; }
  line-height: 1.5;
  color: $corporate;
  background: $background;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%; }

*,
*:before,
*:after {
  box-sizing: border-box; }



// Selection
// =========================================================

::selection {
  background: $blue-light; }

::-moz-selection {
  background: $blue-light; }

img::selection {
  background: transparent; }

img::-moz-selection {
  background: transparent; }

body {
  -webkit-tap-highlight-color: $blue-light; }
