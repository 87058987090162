// Style Guide > Dokumentation
// =========================================================

.doku {

  img {
    @include margin(null null 1.5em); // 24
    border: 4px solid $corporate-border;

    @media only screen and (min-width: 30em) {
      max-width: 95%;
      @include margin(null 2.5%); } }

  p {

    &:last-of-type {
      margin-bottom: 1.5em; } } // 24

  ul {
    @include margin(1.5em null); // 24
    padding-left: 2.25em; // 36
    list-style-type: square; }

  code {
    @include padding(.142857143em .5em 0); // 2 7
    font: {
      family: $proxima-cond;
      size: .875em; // 14
      weight: 600; }
    line-height: 1.714285714; // 24
    border-radius: 2px;
    background-color: $corporate-border; } }



// Module 2 (Video)
// ------------------------------------------

.doku__modul--2 {

  + p {
    margin-bottom: 2.142857143em; // 30
    @include padding(.5em .5em null); // 7
    font-size: .875em; // 14

    a {

      &:first-of-type {
        margin-left: .5em; } // 7

      &:after {
        content: "|";
        @include padding(null .5em); // 7
        color: $corporate; }

      &:last-of-type {

        &:after {
          display: none; } } } } }
