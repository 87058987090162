// 2 Column Page Layout
// =========================================================

#layout__2columns {
  display: flex;
  max-width: 75em; // 1200px
  margin: 0 auto;
  // margin: 0 auto 3.75em // 0 60px
  padding: 0 .625em; // 10px
  background-color: #fff;

  @media only screen and (min-width: 64em) {
    padding: 0 3em; } // 48px

  @media only screen and (min-width: 75em) {
    padding: 0 4em; } // 64px




  // Content Module 3: Text & CTA
  // Content Module 4: Text & Marginal
  // Content Module 8: Download CTA
  // ------------------------------------------

  // !! FIX CTA Box Width for 2 column layout

  .content__mod--text-cta,
  .content__mod--text-marginal {

    .u-m-2of3 {

      @media only screen and (min-width: 48em) and (max-width: 63.9375em) {
        width: 100% !important;
        flex: none !important; } } }

  .content__mod--text-marginal {

    figure {

      @media only screen and (min-width: 48em) and (max-width: 63.9375em) {
        padding: 0;
        border-left: none; } } }



  // Content Module 5: Blockquote
  // ------------------------------------------

  .content__mod--blockquote {

    blockquote {

      @media only screen and (min-width: 48em) {
        @include margin(null 3em); }

      &:before {

        @media only screen and (min-width: 48em) {
          left: -.48em; } } } }



  // Content Module 7: Centersearhc CTA
  // =========================================================

  .content__mod--cta-image {

    @media only screen and (min-width: 48em) {
      @include padding(1.625em); } // 26px

    img {
      max-width: 9.375em; // 150px

      // @media only screen and (min-width: 48em)
      //   max-width: 9.375em // 150px

      @media only screen and (min-width: 64em) {
        max-width: 18.75em; } } } // 300px

  // Content Module 8: Download CTA
  // =========================================================

  .content__mod--cta-dl {
    // @include padding(1em) // 16px
    // box-shadow: 1px 1px 3px rgba(0,2,6,0.1)
    // border: 1px solid $corporate-border
    // border-radius: .25em .5em .5em .5em // 4px 8px
    // background-color: $blue-light

    // @media only screen and (min-width: 48em)
    //   @include padding(1.625em) // 26px

    // h3
    //   margin-top: 3.36em // 84px
    //   margin-bottom: .24em // 6px

    //   @media only screen and (min-width: 48em)
    //     margin-top: 0
    //     padding-left: 3.36em // 84px

    .u-textblock {

      @media only screen and (min-width: 48em) and (max-width: 63.9375em) {
        width: 100% !important;
        flex: none !important; }

      + .gc {

        p {
          @media only screen and (min-width: 48em) and (max-width: 63.9375em) {
            padding-left: 5.25em; } } } }

    h3,
    p {

      @media only screen and (min-width: 48em) and (max-width: 63.9375em) {
        text-align: center; } } }



  // Content Module 12: Link Block
  // =========================================================


  .content__mod--link-block {

    .u-m-1of4 {

      @media only screen and (min-width: 48em) {
        width: 33.3333% !important; } } }

  .nav__onpage {

    ul {

      @media only all and (min-width: 48em) {
        columns: 2 auto; } } }



  // Content Module XX: Boxes
  // =========================================================

  .box__ad {
    width: 100%;

    @media only screen and (min-width: 48em) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 3em; } //48

    @media only screen and (min-width: 64em) {
      width: 48%;
      margin-right: 4%;
      margin-bottom: 0; } }


  .sidebar {

    .box__ad {
      width: 100%;
      margin-right: 0; } }

  .box__centers {
    width: 100%;

    @media only screen and (min-width: 48em) {
      width: 100%; }

    @media only screen and (min-width: 64em) {
      width: 48%; } }



  // Helpers > Columnize
  // ------------------------------------------

  .text__columnize--2 {

    @media only screen and (min-width: 48em) {
      columns: 1 auto; }

    @media only screen and (min-width: 60em) {
      columns: 2 auto; } } }
