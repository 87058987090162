// // Content Module: Newsletter
// =========================================================

.content__mod--newsletter {
  @include margin(1.5em null 3em);

  .gc {

    &:last-of-type {
      display: none;

      @media only screen and (min-width: 48em) {
        display: block; } } }

  img {
    border-radius: .25em .5em .5em .5em;
    border: 1px solid $corporate-border;
    box-shadow: 1px 1px 3px rgba(0,2,6,.1); } }
