// Client Preview
// =========================================================

.preview {
  // display: block
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2.25em;
  box-shadow: 0 0 8px 4px rgba(33,33,33,.25);
  background: #212121;
  background: linear-gradient(to bottom, #333 0%, #212121 100%);
  z-index: 9999;

  @media only screen and (min-width: 30em) {
    height: 2em; }

  @media only screen and (max-width: 30em) {
    display: none; } }

.nav__preview {
  overflow: hidden; }

.preview {

  ::-webkit-scrollbar,
  scrollbar {
    display: none; }

  p,
  li {
    font: {
      family: $proxima-cond;
      size: 1.125em; }
    line-height: 2;

    @media only screen and (min-width: 30em) {
      font-size: 1em; } }

  p {
    margin-bottom: 0;
    color: #545454; }

  p,
  a {
    font-weight: 400;
    text-shadow: 0 1px 1px #000; }

  ul {
    display: block;
    margin: 0;
    text-align: left;
    white-space: nowrap;
    overflow: scroll;

    @media only screen and (min-width: 30em) {
      text-align: right; } }

  li {
    display: inline-block;

    &:after {
      content: " | ";
      margin: 0 .5em;
      color: #d2d7d3; }

    @media only screen and (max-width: 30em) {

      &:first-child {

        &:before {
          margin-right: 1.5em;
          content: "Swipe \2194";
          color: #545454;
          text-shadow: 0 1px 1px #000; } } }

    &:last-child {
      margin-right: 0;

      &:after {
        display: none; } } }

  a {
    color: #d2d7d3;

    &:hover {
      color: $green; } // dark accent color

    &:active {
      color: $green-light; } } } // light accent color

