// Stage > Static > Maps
// =========================================================

.stage__static--map {

  .notice {
    color: white;
    font-size: 0.7em;
    text-align: center;

    a:link,
    a:hover,
    a:active,
    a:visited {
      color: white; } }

  .inside {
    @include padding(null 0);
    background-color: #0d4573; }

  .gc {

    &:last-of-type {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: {
        right: 1.5em; // 24
        left: 3em; } // 48

      @media only screen and (min-width: 48em) {
        padding-left: 1.5em; } } } // 24

  input[type="search"] {
    min-width: auto;
    width: 100%; }

  .btn {
    width: 100%;
    margin: {
      bottom: 1.263157895em; } // 24

    &:hover {
      border-color: #fff; }

    &:before {
      display: none; }

    @media only screen and (min-width: 48em) {
      margin-bottom: 0; } }

  h3 {
    color: #fff;
    text-align: center; }

  .message {
    @include padding(.5em);
    font-size: .875em; // 14
    color: #fff; } }

#map-canvas {
  height: 15em; // 240

  @media only screen and (min-width: 48em) {
    min-height: 26.75em; } } // 428

.map__tooltip {
  min-width: 12em;

  .center--link {
    font-weight: normal;
    margin-top: 0.5em;
    display: block; } }
