// Special Type Elements
// =========================================================



// List > Checkmarks
// ------------------------------------------

.checkmarks {
  @include margin(1.5em 0 0);
  @include padding(0 0 0 2em);
  list-style-type: none;

  li {
    margin-bottom: .25em;
    padding-left: 2em;
    background: {
      image: url('../Icons/icon-checkmark.png');
      positon: left top;
      repeat: no-repeat; } } }
