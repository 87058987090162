// Center Pages
// =========================================================

.bodymed-center {

  .intro-block {
    background-color: white;
    width: 100%;
    margin-bottom: 1em; }


  // override for clean sectioning
  .content__mod--divider-blue {
    border-top: 1em solid $background;
    @include margin(3em -.625em);

    @media only screen and (min-width: 48em) {
      @include margin(null -2.125em); }

    @media only screen and (min-width: 64em) {
      @include margin(null -4.5em); }

    @media only screen and (min-width: 75em) { // 1200
      @include margin(null -9em); } }

  // override fixed headers
  .header {
    position: relative;
    top: auto;
    left: auto;

    .header__meta {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;

      + section {
        margin-top: 33px; } } }

  .header {

    + div {
      margin-top: 0;

      // @media only screen and (min-width: 30em)
      //   margin-top: 0 !important

      // @media only screen and (min-width: 48em)
      //   margin-top: 0 !important

      // @media only screen and (min-width: 64em)
 } }      //   margin-top: 0 !important

  h2 {
    text-align: center; }

  .box__teaser {
    position: relative; }

  .intro-text {
    font-size: 1.1em; }

  .main {

    &#layout__1column {

      .content {
        padding-left: 0;

        ul {
          margin: {
            top: 1.5em; // 24
            bottom: 1.5em; } // 24
          padding-left: 1.5em; // 24
          overflow: auto; } } }



    h1 {
      margin-bottom: .9em; } // 36

    address {
      // margin-bottom: 1.5em // 24
      font-style: normal;

      p {
        margin-bottom: 0;

        &.country-name {
          margin-bottom: 1.5em; } } // 24

      a {
        color: inherit;
        font-weight: 400;

        &:hover {
          color: $green-light; } } } }

  dl {
    display: flex;
    flex-wrap: wrap;
    font-size: .875em; // 14

    @media only screen and (min-width: 30em) {
      width: 60%; }

    @media only screen and (min-width: 48em) {
      width: 100%; }

    ~ p {
      font-size: .875em;
      margin-top: 1.714285714em; // 24
      @include padding(0 .5em); }

    dt,
    dd {
      padding: 0; }

    dt {
      width: 60%;
      padding-left: .5em; }

    dd {
      width: 40%;
      padding-right: .5em;

      span {
        display: block; } } }

  .footer {
    display: block;
    // flex-direction: column
    // justify-content: space-between
    max-width: 75em; // 1200px
    min-height: 1px;
    @include margin(0 auto);
    @include padding(3em 1.5em 4em);
    text-align: center;
    // background-color: $blue-medium

    @media only screen and (min-width: 48em) {
      text-align: left; }

    p {
      margin-bottom: 0; }

    a[target="map"] {

      img {
        border-radius: 0.25em 0.5em 0.5em;
        filter: grayscale(1);
        @include transition(all 0.15s ease-out); }

      &:hover {

        img {
          filter: grayscale(0); } } }

    .tablet-visible {
      margin-top: .75em; } // 12

    .tablet-hidden {
      margin-top: 1.5em; } // 24

    address {
      font-style: normal;

      p {
        margin-bottom: 0;

        &.country-name {
          margin-bottom: 1.5em; } } // 24

      a {
        color: inherit;
        font-weight: 400;

        &:hover {
          color: $green-light; } } }

    nav {

      h4 {
        margin-bottom: .6em; } } // 12

    .nav__legal {
      text-align: center;
      font-size: .875em;

      @media only screen and (min-width: 48em) {
        text-align: right; }

      li {
        display: inline-block;

        &:after {
          margin: 0 .5em 0 .714285714em; // 7 10
          content: "|"; }

        &:last-child {

          &:after {
            display: none; } } } } } }

.placeholder {
  width: 300px;
  height: 300px;
  background-color: #cecece; }

.center__event,
.center__news {
  margin-bottom: 3em; // 36

  &:last-of-type {
    margin-bottom: 0; } }

.center__event {

  + h2 {
    margin-top: 2.4em; } } // 72

.center__event--title,
.center__news--title {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em; // 24

  @media only screen and (min-width: 30em) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }

  h3 {
    margin-bottom: 0;
    text-align: center;

    @media only screen and (min-width: 30em) {
      text-align: left; } }

  time {
    text-align: center;

    @media only screen and (min-width: 30em) {
      text-align: right; }

    span {
      display: block; }

    .date {
      font-weight: 700; } } }

.center__event--content,
.center__news--content {
  @include padding(null 1.625em); } // 26

.center__event--content,
.center__news--content,
.center__services--bodymed,
.center__services--center {

  img {
    border-radius: .25em .5em .5em .5em;

    @media only screen and (min-width: 48em) {
      max-width: 25%; } } }

#fav-star {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 38px;
  width: 38px;
  padding: 6px;
  line-height: 1em;
  font-size: 26px;
  text-align: center;
  line-height: 1;
  color: #fff;
  background-color: $corporate;
  border-radius: 50%;
  cursor: pointer;
  @include transition(all 0.15s ease-out);

  &:hover {
    background-color: $green-light; } }



//  Center > Services
// =========================================================

.center__services--center {

  .content__mod--divider {

    &:not(:first-of-type) {
      border-color: $corporate-border; } } }

//  Center > Event > New > Again
// =========================================================

.center__event {
  border-radius: 4px;
  border: 1px solid rgba(13, 69, 115, 0.1);
  box-shadow: 1px 1px 3px rgba(0, 2, 6, 0.1);

  .center__event--title {
    border-radius: 0;
    border: 0;
    box-shadow: 0 0 0 rgba(0,0,0,0);

    h3 {

      @media only screen and (min-width: 48em) {

        &:before {
          content: "";
          display: block;
          float: left;
          width: 36px;
          height: 36px;
          margin: {
            top: -6px;
            right: .5em; }
          background: {
            image: url('../Icons/icon-calendar.svg');
            size: 36px 36px;
            position: center center;
            repeat: no-repeat; } } } } }

  .center__event--content {
    padding-bottom: 1.5em; } }

.center__news {
  border: 1px solid rgba(13, 69, 115, 0.1);
  box-shadow: 1px 1px 3px rgba(0, 2, 6, 0.1);

  .center__news--title {
    border-radius: 0;
    border: 0;
    box-shadow: 0 0 0 rgba(0,0,0,0); }

  .center__news--content {
    padding-bottom: 1.5em; } }


.inline-video {

  max-width: 100%;
  width: 350px;
  margin: 1em auto;

  @media only screen and (max-width: 48em) {
    clear: left; }

  @media only screen and (min-width: 48em) {
    float: right;
    margin: 1em 0 1em 1em; } }



.videoWrapper {
  position: relative;
  padding-bottom: 56.25% /* 16:9 */;
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }

