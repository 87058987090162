// Navigation > Regional Landing Pages
// =========================================================

.nav__location {

  ul {
    display: flex;
    flex-wrap: wrap; }

  li {
    display: block;
    width: 100%;
    text-align: center;
    border: 1px solid #fff;

    @media only screen and (min-width: 30em) {
      width: 50%; }

    @media only screen and (min-width: 48em) {
      width: 33.3333333%; }

    @media only screen and (min-width: 64em) {
      width: 25%; }

    a {
      display: block;
      @include padding(1.25em .625em); // 20 10
      font-weight: 600 !important;
      color: $corporate !important;
      background-color: $blue-medium;

      &:hover {
        color: #fff !important;
        background-color: $green-light; } } } }
