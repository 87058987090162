// Style Guide
// =========================================================



// Style Guide > Login Box
// ------------------------------------------

.tx-felogin-pi1 {
  margin-top: 3em; } // 48



// Style Guide > Description Box
// ------------------------------------------

.content__mod,
.gr {

  + .sg__details {
    margin-top: 6em; } } // 96

.sg__details {
  margin-bottom: 2em; // 32
  @include padding(.5em 1.5em); // 8 24
  border-left: 4px solid $blue-medium;
  background-color: $blue-lighter;

  &:before,
  p {
    font-family: $proxima-cond; }

  &:before {
    content: "Modul Beschreibung";
    margin-bottom: 0;
    font: {
      size: .625em; // 10
      font-weight: 600; }
    color: #b2cee4;
    line-height: 1;
    text-transform: uppercase; }

  h2,
  p {
    color: $border; }

  h2 {
    margin-bottom: 1em; // 20
    font-size: 1.25em; } // 20

  p {
    margin-bottom: .5em;
    font-size: .875em; // 14

    &:last-of-type {
      border-bottom: 0; } } }
