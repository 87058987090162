// Device Hidden
// =========================================================


.hidden {
  display: none;
  visibility: hidden; }

// Mobile
// ------------------------------------------

.mobile-hidden {
  display: none !important; }

@media only screen and (min-width: 30em) {

  .mobile-hidden {
    display: block !important; } }

.mobile-visible {
  display: block !important; }

@media only screen and (min-width: 30em) {

  .mobile-visible {
    display: none !important; } }



// Tablet
// ------------------------------------------

.tablet-hidden,
.tablet-hidden--flex {
  display: none !important; }

@media only screen and (min-width: 48em) {

  .tablet-hidden {
    display: block !important; }

  .tablet-hidden--flex {
    display: flex !important; } }

.tablet-visible {
  display: block !important; }

@media only screen and (min-width: 48em) {

  .tablet-visible {
    display: none !important; } }



// Desktop
// ------------------------------------------

.desktop-hidden {
  display: block !important; }

@media only screen and (min-width: 64em) {

  .desktop-hidden {
    display: none !important; } }

.desktop-visible {
  display: none !important; }

@media only screen and (min-width: 64em) {

  .desktop-visible {
    display: block !important; } }
