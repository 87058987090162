// Footer > Main
// =========================================================

.footer__main {

  .inside {
    margin: {
      left: auto;
      right: auto; }

    > .gc {

      &:first-of-type,
      &:last-of-type {
        padding-right: 1.5em; } // 24

      @media only screen and (min-width: 48em) {

        &:first-of-type {
          padding-right: 0; } } } }

  address {
    margin-bottom: 2.5em; // 40
    font-style: normal;

    p {
      margin-bottom: 0;

      &.tel {
        margin-top: 1.5em; } } // 24

    a {
      font-weight: 400; }

    + p {
      margin-bottom: .5em; } } // 8

  form {
    @media only screen and (max-width: 48em) {
      display: flex;
      justify-content: center; } }

  input[type="search"],
  button,
  input[type="submit"] {
    border-top: 1px solid $blue-light;
    border-bottom: 1px solid $blue-light; }

  input[type="search"] {
    border-left: 1px solid $blue-light; }

  button,
  input[type="submit"] {
    border-right: 1px solid $blue-light; }

  .gc {

    &:first-of-type,
    &:last-of-type {
      text-align: center;

      @media only screen and (min-width: 48em) {
        text-align: left; } }

    &:nth-of-type(2),
    &:nth-of-type(3),
    &:last-of-type {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }

    &:nth-of-type(2),
    &:nth-of-type(3) {

      nav {
        margin-bottom: 2em; } } // 32

    &:last-of-type {

      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 3em; // 48
        font-size: .875em; // 14

        @media only screen and (min-width: 48em) {
          justify-content: flex-start;
          margin-top: 0; } }

      li {

        &:first-child:after {
          content: "|";
          margin: 0 .5em; } } // 7

      h3 {

        + ul {

          li {

            &:first-child:after {
              content: ""; }

            &:after {
              margin: 0 .5em; } // 7

            a {
              opacity: .75;

              &:hover {
                opacity: 1; } } } } } } } }
