// Box > Stage Static
// =========================================================

.box__teaser {
  background-color: #fff;
  background-color: rgba(255,255,255,.9);

  @media only screen and (min-width: 30em) {
    max-width: 70%; }

  @media only screen and (min-width: 48em) {
    max-width: 25em; } // 400px

  ul {
    @include margin(1.5em 0); // 24px
    @include padding(null null null 2.5em);
    list-style-type: disc; } }


// Options

.stage__option--large {
  max-width: 100%;

  .slick-dots {
    max-width: 75em;
    margin: 0 auto; }

  .stage__option--box {
    max-width: 75em;
    margin: 0 auto; }

  .slider-dots-wrapper {
    background-color: $blue-medium; } }
