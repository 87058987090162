.c-cookies {
  padding: 1em;
  width: 100%;
  background-color: $blue-lighter;
  position: fixed;
  bottom: 0;
  z-index: 9999999999;
  color: white;
  box-shadow: 0 -1px 3px rgba(0,0,0,0.1);
  display: none;

  p {
    color: #0d4573; }

  .container {
    display: flex;
    max-width: 75em;
    margin: 0 auto; }

  a, a:hover {
    color: $green; }
  a:hover {
    text-decoration: underline; }

  &.js-cookies-accepted {
    display: none; }

  &.js-cookies-unaccepted {
    display: block; } }
